import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import queryTagTypes from "./queryTagTypes";
import { getLocalStorage, setLocalStorage } from "@/utils/helpers";
import C from "@/utils/constants";
import ApiServices from "@/utils/api";
import jwt_decode from "jwt-decode";

export const EDT_BASE_API_REDUCER_KEY = "base-api";

const logout = () => {
  window.location.href = "/logout";
};

const customBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const edtAccessToken = getLocalStorage(C.STORAGE_TOKEN);
    if (edtAccessToken) {
      headers.set("Authorization", `Bearer ${edtAccessToken}`);
    }
    return headers;
  },
});

const authQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await customBaseQuery(args, api, extraOptions);
  const accessToken = getLocalStorage(C.STORAGE_TOKEN);
  if (!!accessToken && accessToken !== "null") {
    try {
      const decode: any = jwt_decode(accessToken);
      if (decode.exp * 1000 > new Date().getTime()) {
        return result;
      }

      const edtRefreshToken = getLocalStorage(C.STORAGE_REFRESH_TOKEN);

      if (!!edtRefreshToken && edtRefreshToken !== "null") {
        const apiAuth = new ApiServices();
        const response = await apiAuth.refreshToken(edtRefreshToken);
        setLocalStorage(C.STORAGE_TOKEN, response?.accessToken || "");
        setLocalStorage(C.STORAGE_REFRESH_TOKEN, response?.refreshToken || "");
        result = await customBaseQuery(args, api, extraOptions);
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  } else {
    logout();
  }

  return result;
};

const baseApi = createApi({
  reducerPath: EDT_BASE_API_REDUCER_KEY,
  baseQuery: authQuery,
  tagTypes: Object.values(queryTagTypes),
  endpoints: () => ({}),
});

export default baseApi;
