import { Image, theme, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import LogoSmallNew from "@/assets/images/logo.png";
import ResizeIcon from "@/assets/icons/resize.png";
import styles from "../dashboardLayout.module.less";
import C from "@/utils/constants";

const { useToken } = theme;
const ICON_COLOR = "#8591a6";

interface ISidebarProps {
  routes: any[];
  collapsed: boolean;
  onToggleSidebar: () => void;
  isTablet?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Sidebar({ routes = [], collapsed = false, onToggleSidebar = () => false, isTablet = false }: ISidebarProps) {
  const { token } = useToken();
  const location = useLocation();

  const checkActive = (key: string) => (location.pathname || "").match(key) !== null;

  const logoStyles = {
    width: collapsed ? 160 : "100%",
    transform: collapsed ? "rotate(270deg)" : "rotate(0deg)",
    translate: collapsed ? "-53px -5px" : "0 0",
  };

  return (
    <div className={styles.sidebarWrapper}>
      <div
        className={styles.imgWrapper}
        style={{
          justifyContent: collapsed ? "start" : "center",
        }}
      >
        <Image src={LogoSmallNew} style={logoStyles} preview={false} fallback={C.IMAGE_DEFAULT} />
      </div>

      <div className={styles.menuWrapper}>
        {routes
          .filter(({ layout, sideBar }) => layout === "admin" && sideBar)
          .map(({ title, icon, path, tag }, index) => (
            <div className={styles.menuItem} key={index}>
              <Link
                to={path}
                className={styles.menuItemLeft}
                style={{
                  background: checkActive(tag) ? token.colorPrimary : "#dfe3ef",
                  borderColor: checkActive(tag) ? "#DA590E" : "#d3d8e3",
                  color: checkActive(tag) ? "#fff" : "#757575",
                  paddingLeft: collapsed ? 0 : 28,
                }}
              >
                {!collapsed && <span>{title}</span>}
              </Link>
              <Link
                to={path}
                className={styles.menuItemRight}
                style={{
                  background: checkActive(tag) ? "#DE5706" : "#d3d7e1",
                  borderColor: checkActive(tag) ? "#BC4701" : "#c9cdd7",
                }}
              >
                {icon({
                  color: checkActive(tag) ? "#fff" : ICON_COLOR,
                })}
              </Link>
            </div>
          ))}

        <div className={styles.menuItem}>
          <div
            className={styles.menuItemLeft}
            style={{
              borderBottom: 0,
            }}
          >
            {!collapsed && (
              <Button type="primary" className={styles.buttonCollapse} onClick={onToggleSidebar}>
                <Image
                  src={ResizeIcon}
                  style={{
                    width: 24,
                  }}
                  preview={false}
                  fallback={C.IMAGE_DEFAULT}
                />
              </Button>
            )}
          </div>
          <div
            className={styles.menuItemRight}
            style={{
              borderBottom: 0,
              justifyContent: "start",
            }}
          >
            {collapsed && (
              <Button
                type="primary"
                className={styles.buttonCollapse}
                style={{
                  width: 28,
                  height: 28,
                }}
                onClick={onToggleSidebar}
              >
                <Image
                  src={ResizeIcon}
                  style={{
                    width: 18,
                  }}
                  preview={false}
                  fallback={C.IMAGE_DEFAULT}
                />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
