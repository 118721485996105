import { persistor, store } from "@/app/store";
import "@/i18n";
import theme from "@/theme";
import { ConfigProvider, Result, notification } from "antd";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import { Redirect, Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import DashboardLayout from "./components/layouts/DashboardLayout";
import useWindowDimensions from "./hooks/useWindowDimention";
import DialogConfirmationModule from "./modules/DialogConfirmation";
import routes from "./routes";
import "./styles/global.css";
import C from "./utils/constants";

function App() {
  const mql = window.matchMedia("(orientation: portrait)");
  const windowDimension = useWindowDimensions();

  const isPortrait = mql.matches;
  const isMobile = windowDimension.width * 0.6 < C.TABLET_MAX_WIDTH;

  notification.config({
    placement: "topRight",
  });

  return (
    <>
      {isMobile && isPortrait ? (
        <Result
          status="info"
          title="Rotate the screen horizontally to display the website"
          subTitle="Please follow the instructions"
        />
      ) : (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider theme={theme}>
              <BrowserRouter>
                <Switch>
                  {Object.values(routes)
                    .filter(({ layout }) => layout !== "admin")
                    .map(({ path, component }, index) => (
                      <Route
                        exact
                        path={path}
                        component={component}
                        key={index}
                      />
                    ))}
                  <Route path="/" component={DashboardLayout} />
                  <Redirect from="*" to="/" />
                </Switch>
              </BrowserRouter>
              <DialogConfirmationModule />
            </ConfigProvider>
          </PersistGate>
        </Provider>
      )}
    </>
  );
}

export default App;
